<template>
  <vx-card>
    <form>
      <div class="mb-4 px-4">
        <fieldset class="vx-row rounded-lg pb-3 px-1 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('fields.title') }}</strong></legend>
          <div class="vx-col my-2 md:w-1/3 w-full">
            <vs-input v-validate="'required'" class="w-full" name="name_tm" placeholder="Turkmen" v-model="region.name_tm" />
            <span class="text-danger text-sm" v-show="errors.has('name_tm')">{{ errors.first('name_tm') }}</span>
          </div>
          <div class="vx-col my-2 md:w-1/3 w-full">
            <vs-input v-validate="'required'" class="w-full" name="name_ru" placeholder="Russian" v-model="region.name_ru" />
            <span class="text-danger text-sm" v-show="errors.has('name_ru')">{{ errors.first('name_ru') }}</span>
          </div>
          <div class="vx-col my-2 md:w-1/3 w-full">
            <vs-input v-validate="'required'" class="w-full" name="name_en" placeholder="English" v-model="region.name_en" />
            <span class="text-danger text-sm" v-show="errors.has('name_en')">{{ errors.first('name_en') }}</span>
          </div>
        </fieldset>
      </div>
      <div class="mb-4">
        <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('fields.properties') }}</strong></legend>
          <ul class="vx-row centerx px-4">
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio v-validate="'required'" name="type" v-model="type" vs-value="cities">{{$t('city')}}</vs-radio>
            </li>
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio v-validate="'required'" name="type" v-model="type" vs-value="regions">{{$t('region')}}</vs-radio>
            </li>
          </ul>
          <span class="text-danger text-sm pl-4" v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </fieldset>
      </div>
      <div v-if="type === 'regions'" class="mb-4">
        <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{$t('details')}}</strong></legend>
          <div class="vx-row" :key="type">
            <div class="vx-col w-1/2 my-5 mx-4">
              <template>
                <v-select v-validate="'required'" :options="cities" name="childId" :placeholder="`${$t('select')} ${$t('city')}`" v-model="region.cityId" :label="`name_${$i18n.locale}`" :reduce="opt => opt.id" />
              </template>
              <span class="text-danger text-sm" v-show="errors.has('childId')">{{ errors.first('childId') }}</span>
            </div>
            <div class="vx-col mt-5 w-1/3">
              <li class="vx-col ml-5 mr-4 flex">
                <vs-switch v-model="region.isExpress" />
                <label class="ml-3">{{ $t('isExpress') }}</label>
              </li>
            </div>
            <div v-if="region.isExpress" class="vx-col w-full mb-4 mx-4">
              <div class="">
                <vs-input v-validate="'required|numeric'" data-vv-as="field" class="w-full" name="url" :placeholder="$t('deliveryDuration')" v-model="region.express_time" />
                <span class="text-danger text-sm" v-show="errors.has('url')">{{ errors.first('url') }}</span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div v-else-if="type === 'cities'" class="mb-4">
        <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{$t('details')}}</strong></legend>
          <div class="vx-row" :key="type">
            <div class="vx-col w-1/2 my-5 mx-4">
              <vs-input v-validate="'required'" data-vv-as="field" class="w-full" name="code" placeholder="Code" v-model="region.code" />
              <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>
            </div>
          </div>
        </fieldset>
      </div>
      <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">{{$t('submit')}}</vs-button>
    </form>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      region: {
        name_tm: '',
        name_ru: '',
        name_en: '',
        code: '',
        photo: null,
        cityId: null,
        isExpress: false,
        express_time: null
      },
      type: null,
      cities: []
    }
  },
  created () {
    this.fetchCities()
  },
  methods: {
    async fetchCities () {
      await this.$http.get('/cities').then(response => {
        this.cities = response.data
      })
    },
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post(`/${this.type}/add`, {
            ...this.region
          }).then(response => {
            if (response.status === 200) {
              this.$vs.notify({
                title: 'Success',
                text: 'Region successfully created!',
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'primary'
              })
              this.$router.push('/regions')
            }
          }).catch(err => {
            this.$vs.notify({
              title: 'Error',
              text: err.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
        } else {
          // form have errors
        }
      })
    }
  }
}
</script>
